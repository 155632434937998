@import 'core/styling/App.scss';

.hour-container {
    width: 100%;
    height: 40px; // not exact, prevents events from overflowing parent container when grid becomes too big
    border-right: 1px solid $textColorLight;
    border-bottom: 1px solid $textColorLight;
    flex: 1 1 20%;
}

.half-hour {
    position: relative;
    display: flex;
    height: 100%;
    &:last-child {
        margin-right: 15px;
    }
}

.current-time {
    z-index: 4;
    pointer-events: none;
    position: absolute;
    border-top: 1.5px solid red;
    width: calc(100% + 15px);
}

.highlight-region {
    width: 100%;
    // height: 200%;
    background: green;
    opacity: .5;
}
