@import 'core/styling/App.scss';

// .lds-roller {
//     display: inline-block;
//     position: relative;
//     width: 80px;
//     height: 80px;
// }
// .lds-roller > div {
//     animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
//     transform-origin: 40px 40px;
// }
// .lds-roller div .div-after {
//     content: ' ';
//     display: block;
//     position: absolute;
//     width: 7px;
//     height: 7px;
//     border-radius: 50%;
//     background: #fff;
//     margin: -4px 0 0 -4px;
// }
// .lds-roller div:nth-child(1) {
//     animation-delay: -0.036s;
// }
// .lds-roller div:nth-child(1) .div-after {
//     top: 63px;
//     left: 63px;
// }
// .lds-roller div:nth-child(2) {
//     animation-delay: -0.072s;
// }
// .lds-roller div:nth-child(2) .div-after {
//     top: 68px;
//     left: 56px;
// }
// .lds-roller div:nth-child(3) {
//     animation-delay: -0.108s;
// }
// .lds-roller div:nth-child(3) .div-after {
//     top: 71px;
//     left: 48px;
// }
// .lds-roller div:nth-child(4) {
//     animation-delay: -0.144s;
// }
// .lds-roller div:nth-child(4) .div-after {
//     top: 72px;
//     left: 40px;
// }
// .lds-roller div:nth-child(5) {
//     animation-delay: -0.18s;
// }
// .lds-roller div:nth-child(5) .div-after {
//     top: 71px;
//     left: 32px;
// }
// .lds-roller div:nth-child(6) {
//     animation-delay: -0.216s;
// }
// .lds-roller div:nth-child(6) .div-after {
//     top: 68px;
//     left: 24px;
// }
// .lds-roller div:nth-child(7) {
//     animation-delay: -0.252s;
// }
// .lds-roller div:nth-child(7) .div-after {
//     top: 63px;
//     left: 17px;
// }
// .lds-roller div:nth-child(8) {
//     animation-delay: -0.288s;
// }
// .lds-roller div:nth-child(8) .div-after {
//     top: 56px;
//     left: 12px;
// }
// @keyframes lds-roller {
//     0% {
//       transform: rotate(0deg);
//     }
//     100% {
//       transform: rotate(360deg);
//     }
// }

.spinner {
    border: 3px solid #f3f3f3;
    border-top: 3px solid $secondaryColor;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1.5s linear infinite;
}

  /* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}