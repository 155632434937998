.confirmation-message {
    white-space: pre-wrap;
    padding-bottom: 30px;
    padding-left: 10px;
    padding-right: 10px;
}

.confirmation-button-row {
    display: flex;
    margin: 0 -10px 0 0;
}

.confirmation-button {
    width: 100%;
    margin: 0 10px 0 0;
}