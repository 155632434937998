@import 'core/styling/App.scss';

.sidebar-container {
    height: 100vh;
    width: 280px;
    z-index: 5;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: $primaryColor;
    overflow-x: hidden;
    box-shadow: 2px 0.2rem 0.5rem 0.2rem rgba(0, 0, 0, .26);
    transition: ease all .5s;
    &.hide {
        width: 50px;
    }
    &.standalone {
        position: fixed;
    }
}
.sidebar-wrapper {
    height: 100%;
    transition: ease all .5s;
    &.hide {
        opacity: 0;
    }
    &.show {
        opacity: 1;
    }
}

.sidebar-content {
    height: calc(100% - 360px);
    height: -moz-calc(100% - 360px);
    height: -webkit-calc(100% - 360px);
    height: -o-calc(100% - 360px);
    overflow-y: scroll;
}

.sidebar-header {
    margin: 20px 0;
    width: 140px;
    height: 140px;
    opacity: .8;
}

.sidebar-header-container {
    display: flex;
    justify-content: center;
}

.sidebar-navigation {
    display: flex;
    align-items: center;
    height: 70px; // navbar height
    text-align: center;
    font-size: 18px;
    padding: 0 16px 10px 16px;
    color: white;
}

.sidebar-navigation-item {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid white;
    width: 100%;
    cursor: pointer;
    margin: 10px;
    padding: 10px;
    opacity: .4;
    &:hover {
        opacity: 1;
    }
}

.sidebar-title {
    padding: 10px 20px 10px 20px;
    text-decoration: none;
    text-transform: uppercase;
    color: white;
    fill: white;
    display: block;
    opacity: .4;
}

.sidebar-row {
    display: flex;
    align-items: center;
    margin: 0 -10px 0 0;
    &.space-between {
        justify-content: space-between;
    }
}

.sidebar-row-item {
    margin: 0 10px 0 0;
}

.sidebar-icon {
    fill: white;
    height: 25px;
    width: 25px;
}

.sidebar-content-container {
    padding: 10px 20px;
    text-decoration: none;
    color: white;
    fill: white;
    display: block;
    cursor: pointer;
    opacity: .4;
    &.selected {
        opacity: 1;
        color: white;
        // color: $tertiaryColor;
        // fill: $tertiaryColor;
        // text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    }
    &:hover {
        opacity: 1;
        background-color: $secondaryColor;
    }
    &.title {
        opacity: .4;
        background-color: transparent;
        &:hover {
            opacity: 1;
        }
    }
    &.footer {
        position: absolute;
        bottom: 0;
        &:hover{
            background-color: transparent;
        }
    }
}

.sidebar-footer {
    max-height: 150px;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px;
    color: white;
    // opacity: .4;
    white-space: nowrap;
    text-align: center;
    text-transform: uppercase;
    font-size: 80%;
    &:hover {
        opacity: 1;
    }
}

.top-bar {
    width: 100%;
    position: relative;
}

.sidebar-button {
    z-index: 2;
    position: absolute;
    top: 10px;
    right: 10px;
    fill: white;
    opacity: .4;
    height: 2.5rem;
    width: 2.5rem;
    cursor: pointer;
    &.small {
        height: 2rem;
        width: 2rem;
    }
    &:hover {
        opacity: 1;
    }
}
// .sidebar-button {
//     position: relative;
//     display: none;
//     cursor: pointer;
// }

@media only screen and (max-device-width: 768px){
    .sidebar-container {
        width: 220px;
    }

    // .sidebar-button {
    //     fill: white;
    //     display: inline;
    //     top: 0px;
    //     right: 0;
    //     position: absolute;
    //     height: 110px;
    //     width: 110px;
    //     opacity: .4;
    // }

    // .sidebar-icon{
    //     height: 50px;
    //     width: 50px;
    // }

    // .sidebar-row-item{
    //     font-size: 28px;
    // }

    // .sidebar-header{
    //     opacity: 0;
    // }

    // .sidebar-navigation-item{
    //     font-size: 28px;
    // }

    // .sidebar-title{
    //     font-size: 24px;
    //     Margin: 10px 0 10px 0
    // }

    // .sidebar-content-container{
    //     padding: 10px;
    // }

}