@import 'core/styling/App.scss';

.event {
    width: 100%;
    color: white;
    background-color: $primaryColor;
    border-radius: .2rem;
    font-size: 12px;
    cursor: pointer;
    box-shadow: 0px -1px 2px white, 0px 1px 2px white;
    overflow: hidden;
    padding: 0 5px;
}

.event-row{
    display: flex;
    justify-content: space-between;
}


@media only screen and (max-device-width: 768px){
    .event-header{
        font-size: 14px;
       }
       
}


.window-header {
    display: flex;
    align-items: center;
    margin: 0 2.5rem 0 0;
    font-weight: 600;
    font-size: 1.55rem;
}

.window-type {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
    background-color: $primaryColor;
}

.window-icon {
    height: 2.5rem;
    width: 2.5rem;
    &:hover {
        cursor: pointer;
        fill: $tertiaryColor;
    }
}

.signed-up {
    color: white;
    background-color: #9ACD32;
    border-radius: .5rem;
    padding: 2px 8px;
}