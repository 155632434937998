@import 'core/styling/App.scss';

.subscription-container {
    border: 4px solid transparent;
    border-radius: .2rem;
    cursor: pointer;
    &.selected {
        border: 4px solid $primaryColor;
        border-radius: .4rem;
    }
    align-items: stretch;
}

.subscription-row {
    display: flex;
    flex-direction: row;
    margin: 0 -10px 0 0;
}

.subscription-plan {
    width: 100%;
    margin: 0 10px 0 0;
    flex-grow: 1;
}

.subscription-plan-price {
    font-size: 35px;
    font-weight: 600;
    padding: 1.5rem;
}

.subscription-plan-subheader {
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 1.5rem;
}

.subscription-plan-body {
    font-size: 14px;
    font-weight: 400;
    padding-bottom: 1.5rem;
    text-align: center;
}

.feature {
    font-size: 15px;
    display: flex;
    align-items: center;
    padding: .5rem;
}

.feature-indicator {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
    background-color: $primaryColor;
}

.solid {
    border: 1px solid $primaryColor;
    border-radius: .5rem;
}