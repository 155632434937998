@import 'core/styling/App.scss';

.table-container {
    position: relative;
    ul {
        padding-left: 0; 
    }
}
  
.responsive-table {
    li {
        border-radius: 3px;
        padding: 15px 20px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
        // overflow: scroll;
    }

    .table-header {
        background-color: $secondaryColor;
        color: white;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 0.03em;
    }

    .table-row {
        background-color: #ffffff;
        box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.1);
        height: 78px;

    }
    
    @media all and (max-width: 1032px) {
        .table-header {
            display: none;
        }

        .table-row{
            height: auto;
        }

        li {
            display: block;
        }

        .col {
            flex-basis: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .col {
            display: flex;
            padding: 10px 0;
            &:before {
                font-weight: 600;
                color: $tertiaryColor;
                padding-right: 10px;
                content: attr(data-label);
                flex-basis: 50%;
                text-align: right;
            }
        }

        .col:last-child {
            padding-top: 30px;
            flex-direction: column;
        }
    }
}

.table-icon {
    fill: $primaryColor;
    height: 2.5rem;
    width: 2.5rem;
    &:hover {
        cursor: pointer;
        fill: $tertiaryColor;
    }
    &.white {
        fill: white;
        &:hover {
            fill: $tertiaryColor;
        }
    }
}

.navigation-container {
    position: absolute;
    bottom: 0; 
    width: 100%;
}

.search-input-table {
    padding: 0 1rem;
    border-radius: 0.5rem;
    border-width: 1px;
    border-color: rgba(0,0,0,.3);
    width: 25%;
    height: 3.5rem;
    font-family: $font_0, $font_1;
    overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
    outline: 1;
    &.warning {
        border-color: red;
        border-width: 2px;
    }
    &:focus {
        outline-color: $primaryColor;
    }
}