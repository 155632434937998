@import 'core/styling/App.scss';

.main {
    margin-left: 280px;
    // padding: 10px;
    transition: ease all .5s;
    &.collapsed {
        margin-left: 50px;
    }
}

@media screen and (max-device-width: 768px){
    .main {
        margin-left: 0;
    }
}