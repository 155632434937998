@import 'core/styling/App.scss';

.card {
    position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	// margin-bottom: 1.5rem;
    height: 100%;
	background-color: $cardColor;
	border-radius: .2rem;
	box-shadow: 0 0.2rem 0.5rem 0.2rem rgba(0, 0, 0, 0.06);
}

.cardHeader {
    background: $primaryColor;
    border-radius: .2rem;
    border-bottom: none;
    color: white;
    padding: 1rem 1.5rem 1rem 1.5rem;
    font-weight: bold;
}

.scroll {
    overflow-y: auto;
}

@media only screen and (max-device-width: 768px){
    .cardHeader{
        font-size: 34px;
    }
}