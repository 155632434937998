//fonts
$font_0: Oxygen;
$font_1: sans-serif;
$font_2: Open Sans;

$primaryColor: var(--primaryColor);
$secondaryColor: var(--secondaryColor);
$tertiaryColor: var(--tertiaryColor);
$textColor: var(--textColor);
$textColorLight: var(--textColorLight);
$bodyColor: var(--bodyColor);
$bodygradientColor: var(--bodygradientColor);
$cardColor: var(--cardColor);

$iconButtonFill: var(--iconButtonFill);

:root {
    --primaryColor: #12A8F8;
    --secondaryColor: #71cbfb;
    --tertiaryColor: #54c0fa;
    --textColor: #1f1f1f;
    --textColorLight:  #ccc;
    --bodyColor: #eef7fc;
    --bodygradientColor: #d2edfb;
    --cardColor: #ffffff;

    --iconButtonFill: #ffffff;
}