.collapse-button {
    display: block;
    width: 100%;
}
  
.collapse-content.collapsed {
    display: none;
}
  
.collapsed-content.expanded {
    display: block;
}