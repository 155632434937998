@import 'core/styling/App.scss';

.day-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $primaryColor;
    color: white;
    height: 30px;
    border-right: 1px solid $textColorLight;
    border-radius: .2rem;
    text-transform: uppercase;
    &.today {
        background-color: $tertiaryColor;
    }
}

.day-spacing {
    min-height: 15px;
    border-right: 1px solid $textColorLight;
    border-bottom: 1px solid $textColorLight;
}

.day-col {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
    width: 100%;
}

// @media screen and (max-device-width: 768px){
//     .day-header {
//         font-size: 1.66rem;
//     }
// }
