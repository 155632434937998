@import 'core/styling/App.scss';
@import '../Input.scss';

.token-row {
    display: flex;
    margin: 0 -10px 0 0;
    flex-wrap: wrap;
}

.token {
    display: flex;
    align-items: center;
    margin: 10px 10px 0 0;
    width: fit-content;
    padding: 5px 15px;
    border-radius: .2rem;
	box-shadow: 0 0.2rem 0.5rem 0.2rem rgba(0, 0, 0, 0.1);
}

.remove-button {
    cursor: pointer;
    padding-right: 10px;
    &:hover {
        font-weight: bolder;
    }
}