@import 'core/styling/App.scss';

.overlay {
    transition: .5s ease;
    opacity: .2;
    position: absolute;
    bottom: .2rem;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.overlay-target {
    opacity: 1;
}

.overlay-container {
    position: relative;
    &:hover {
        // .overlay-target {
        //     opacity: 0.8;
        // }
        .overlay {
            opacity: 1;
            cursor: pointer;
        }
    }
}

.chart-icon {
    fill: $primaryColor;
    height: 3rem; 
    width: 3rem;
    &.large {
        height: 4rem; // 2 x line-height (App.scss -> .headline)
        width: 4rem;
    }
    &.small {
        height: 2rem; // 2 x line-height (App.scss -> .headline)
        width: 2rem;
    }
    &.white {
        fill: white;
    }
    &:hover {
        fill: $tertiaryColor;
        cursor: pointer;
    }
}