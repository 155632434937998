@import 'core/styling/App.scss';
@import '../Input.scss';

.search-container {
    position: 'relative';
    display: flex;
    align-items: center;
    width: 100%;
}

.search-input-clear {
    position: 'absolute';
    cursor: pointer;
    right: 10px;
    background-color: rgba(0,0,0,.3);;
    color: white;
    font-weight: 600;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    text-align: center;
    &:hover {
        background-color: $primaryColor;
    }
}
