@import 'core/styling/App.scss';

// .usermanagement-container {

// }

.user-container {
    padding: 10px 0;
}

.user-row {
    display: flex;
    cursor: pointer;
    padding: 3px 0;
}

.user-row-status {
    font-style: italic;
}

.user-body {
    flex-basis: 50%;
    // border-left: 2px solid $primaryColor;
    border-radius: .2rem;
    padding: 0 50px 0 10px
}

.warning-text {
    color: red;
    margin-top: .5rem;
    margin-bottom: 1rem;
    font-size: 1.1rem;
}

// .section {
//     font-size: 1.3333333333rem;
// 	line-height: 2rem;
// 	letter-spacing: 0.0333333333rem;
//     border-left: 2px solid $primaryColor;
//     padding: 0 0 0 5px;
// }

.subscription-plan-indicator {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
}

.attribute {
    font-weight: 600;
    line-height: 3rem;
}