@import 'core/styling/App.scss';
@import '../Input.scss';

.select-container {
    position: 'relative';
    display: flex;
    align-items: center;
    width: 100%;
}

.select-input-selector {
    position: 'absolute';
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    cursor: pointer;
    right: 10px;
    text-align: center;
    color: rgba(0,0,0,.3);
}

.selector {
    width: 0; 
    height: 0;
    transition: transform 300ms;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid rgba(0,0,0,.3);
    &.open {
        transform: rotate(180deg);
    }
}
