.userpanel {
    margin-left: 280px;
}

.userpanel-row {
    display: flex;
    flex-direction: row;
    margin: 0 -10px 0 0;
    padding-bottom: 10px;
}

.userpanel-row-item {
    width: 100%;
    margin: 0 10px 10px 0;
}

.userpanel-row-table-item {
    flex-basis: 20%;
    :nth-child(1) {
        flex-basis: 10%;
    }
}

.payment-status {
    height: 3rem;
    padding: 0 1rem;
    border-radius: .4rem;
    color: white;
    font-size: 1.111111rem;
	letter-spacing: 0.0333333333rem;
    width: fit-content;
}

.property {
    font-weight: 600;
    line-height: 3rem;
    padding-right: .2rem;
}

.value {
    line-height: 3rem;
    padding-right: .5rem;
    &.danger {
        color: red
    }
}

.subscription-plan-indicator {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
}

.footer {
    position: absolute;
    bottom: 1.5rem;
    right: 1.5rem;
}

.table {
    display: flex;
    flex-direction: row;
    margin: 0 -10px 0 0;
}

.table-row {
    width: 100%;
    margin: 0 10px 0 0;
}

@media only screen and (max-device-width: 768px){

    .userpanel {
        margin-left: 110px;
    }

    .userpanel-row {
        flex-wrap: wrap;
    }

    .property{
        font-size: 26px;
    }
    
    .property-value{
        font-size: 26px;
    }

    .value{
        font-size: 26px;
    }

    .table-row{
        font-size: 26px;
    }
}