.week-row {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    animation: fade-in 400ms;
}

@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}

}

// .slider {
//     animation: slider 500ms;
// }

// @keyframes slider {
// 	from {
// 		transform: translateX(100%);
// 	}
// 	to {
// 		transform: translateX(0%);
// 	}
// }