@import 'core/styling/App.scss';
@import '../Input.scss';

.validation-error {
    white-space: pre-wrap;
    // margin-top: .5rem;
    margin-left: .5rem;
    margin-bottom: 1rem;
    font-size: 1.1rem;
    color: red;
}