@import './index';
@import './pageTransitions';
@import './branding/variables';

@import url("https://fonts.googleapis.com/css?family=Open+Sans:600|Oxygen:400,700&display=swap");

//@extend-elements
//original selectors
//html, body
%extend_1 {
	// padding-top: 60px;
    // margin-top: 70px;
	padding-bottom: 40px;
	color: $textColor;
	font-family: $font_0, $font_1;
	font-weight: 400;
}

//original selectors
//.fluid-container, .responsive-container
%extend_2 {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.app-container {
    // margin-top: 70px;
    padding: 10px;
    transition: ease all .4s;
	// margin-left: 0px;
}



.is-clickable {
	cursor: pointer;
}

.is-fixed {
	position: fixed;
}




.react-datepicker__header--time{
	height: 57px;
}

.react-datepicker__time-container--with-today-button{
	right: -85px;
	top: -1px;
}

.horizontal-stack {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
}

.vertical-stack {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

* {
	box-sizing: border-box;
}

html {
	@extend %extend_1;
    font-size: 12px;
	background-color: $bodyColor;
	background-image: linear-gradient($bodygradientColor, $bodyColor 36%);
	background-attachment: fixed;
	user-select: none;
}

body {
	@extend %extend_1;
	margin-bottom: 2rem;
	font-size: 1.3333333333rem;
    // font-size: 16px;
	line-height: 2rem;
	letter-spacing: 0.0333333333rem;
	margin-bottom: 0;
	&:last-child {
		margin-bottom: 0;
	}
}

select {
    appearance: none;
    background-image:
        linear-gradient(45deg, transparent 50%, rgba(0,0,0,.3) 50%),
        linear-gradient(135deg, rgba(0,0,0,.3) 50%, transparent 50%),
        linear-gradient(to right, #ccc, #ccc);
    background-position:
        calc(100% - 20px) calc(1.2em + 2px),
        calc(100% - 15px) calc(1.2em + 2px),
        calc(100% - 2.5em) 0.5em;
    background-size:
        5px 5px,
        5px 5px,
        1px 2em;
    background-repeat: no-repeat;
}

select:focus {
    background-image:
        linear-gradient(45deg, rgba(0,0,0,.3) 50%, transparent 50%),
        linear-gradient(135deg, transparent 50%, rgba(0,0,0,.3) 50%),
        linear-gradient(to right, #ccc, #ccc);
    background-position:
        calc(100% - 15px) 1.2em,
        calc(100% - 20px) 1.2em,
        calc(100% - 2.5em) 0.5em;
    background-size:
        5px 5px,
        5px 5px,
        1px 2em;
    background-repeat: no-repeat;
}

.fluid-container {
	@extend %extend_2;
}

.responsive-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: 0 auto;
	width: 100%;
	padding: 0 0.5rem;
}

.form-container {
    @extend .responsive-container;
}

@media(min-width : 320px) and (max-width : 480px) {
    .responsive-container {
		width: 140px;
		padding: 0;
	}
    .form-container {
        width: 600px;
    }
}

@media(min-width: 600px) {
	.responsive-container {
		width: 960px;
	}
    .form-container {
        width: 600px;
    }
	.row {
		flex-direction: row;
		.column {
			&:nth-child(odd) {
				margin-right: 1rem;
			}
			&:nth-child(even) {
				margin-left: 1rem;
			}
		}
	}
}

@media(min-width: 992px) {
	.responsive-container {
		width: 960px;
	}
    .form-container {
        width: 600px;
    }
	.row {
		flex-direction: row;
		.column {
			&:nth-child(odd) {
				margin-right: 1rem;
			}
			&:nth-child(even) {
				margin-left: 1rem;
			}
		}
	}
}

@media(min-width: 1200px) {
	.responsive-container {
		width: 1140px;
	}
    .form-container {
        width: 600px;
    }
}

@media(min-width: 1400px) {
	.responsive-container {
		width: 1320px;
	}
    .form-container {
        width: 600px;
    }
}

@media(min-width: 1600px) {
	.responsive-container {
		width: 1550px;
	}
    .form-container {
        width: 600px;
    }
}

@media(min-width: 2000px) {
	.responsive-container {
		width: 1950px;
	}
    .form-container {
        width: 600px;
    }
}

@media only screen and (orientation:landscape) and (max-device-width: 768px){
	.app-container {
	  transform: rotate(90deg);
	}
  }

.row {
	display: flex;
	width: 100%;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	.column {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		width: 100%;
	}
}

.headline {
	margin-bottom: 1rem;
	font-size: 1.3333333333rem;
	line-height: 2rem;
	font-weight: 700;
    &.large {
        font-size: 3.3333333333rem;
        line-height: 4rem;
    }
	&:last-child {
		margin-bottom: 0;
	}
}

a {
	text-decoration: none;
	color: $primaryColor;
    cursor: pointer;
}

u {
	text-decoration: none;
	color: white;
}

p {
	// margin-bottom: 2rem;
	font-size: 1.3333333333rem;
	line-height: 2rem;
	letter-spacing: 0.0333333333rem;
	&:last-child {
		margin-bottom: 0;
	}
}

.p {
	margin-bottom: 2rem;
	font-size: 1.3333333333rem;
	line-height: 2rem;
	letter-spacing: 0.0333333333rem;
	&:last-child {
		margin-bottom: 0;
	}
}

.body {
    // margin-top:
    // margin-bottom: 2rem;
	font-size: 1.3333333333rem;
	line-height: 2rem;
	letter-spacing: 0.0333333333rem;
	&:last-child {
		margin-bottom: 0;
	}
}

.footnote {
	// margin-top: -0.5rem;
	// margin-bottom: 1rem;
	font-size: 1.1666666667rem;
	line-height: 1.5rem;
	color: #7a7a7a;
	&:last-child {
		margin-bottom: 0;
	}
}

@media only screen and (max-device-width: 768px){
	.footnote{
		font-size: 26px;
	}

	.appointment-type{
		font-size: 26px;
	}

	.announcement-message{
		font-size: 26px;
	}

	.user-card-container{
		font-size: 26px;
	}

}

.scrollable {
	overflow-y: auto;
	max-height: 600px;
}

.checkbox {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	span {
		cursor: pointer;
	}
	.logic {
		position: absolute;
		top: 0;
		left: 0;
		height: 0;
		width: 0;
		opacity: 0;
		&:checked ~ .control {
			border-color: $primaryColor;
			.mark {
				background-color: $primaryColor;
			}
		}
	}
	.control {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 2.1666666667rem;
		height: 2.1666666667rem;
		flex: 0 0 2.1666666667rem;
		margin: 1rem;
		border: 0.1666666667rem solid #d6d6d6;
		background-color: white;
		cursor: pointer;
		border-radius: 0.1666666667rem;
		.mark {
			width: 1.1666666667rem;
			height: 1.1666666667rem;
			border-radius: 0.1666666667rem;
		}
	}
	&:hover .logic {
		~ .control {
			border-color: $tertiaryColor;
		}
		&:checked ~ .control {
			border-color: $tertiaryColor;
			.mark {
				background-color: $tertiaryColor;
			}
		}
	}
}

.radio {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	span {
		cursor: pointer;
	}
	.logic {
		position: absolute;
		top: 0;
		left: 0;
		height: 0;
		width: 0;
		opacity: 0;
		&:checked ~ .control {
			border-color: $primaryColor;
			.mark {
				background-color: $primaryColor;
			}
		}
	}
	.control {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 2.1666666667rem;
		height: 2.1666666667rem;
		flex: 0 0 2.1666666667rem;
		margin: 1rem;
		border: 0.1666666667rem solid #d6d6d6;
		background-color: white;
		cursor: pointer;
		border-radius: 50%;
		.mark {
			width: 1.1666666667rem;
			height: 1.1666666667rem;
			border-radius: 50%;
		}
	}
	&:hover .logic {
		~ .control {
			border-color: $tertiaryColor;
		}
		&:checked ~ .control {
			border-color: $tertiaryColor;
			.mark {
				background-color: $tertiaryColor;
			}
		}
	}
}

.button {
	padding: 0 1rem;
	width: 100%;
	height: 3.5rem;
	color: white;
	background-color: $primaryColor;
	border: none;
	border-radius: 0.4rem;
	font-family: $font_0, $font_1;
	font-size: 1.111111rem;
	letter-spacing: 0.0333333333rem;
	cursor: pointer;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	outline: 0;
	&:active {
		background-color: $secondaryColor;
	}
	&:disabled {
		color: #7a7a7a;
		background-color: #b8b8b8;
		cursor: not-allowed;
    }
    &.secondary {
        background-color: $secondaryColor;
    }
    &.danger {
        background-color: darkred;
        &:hover {
            background-color: rgb(180, 0, 0);
        }
        &:active {
            background-color: rgb(214, 0, 0);
        }
    }
    &.selected {
        background-color: $tertiaryColor;
    }
    &.small {
        height: 3rem;
    }
    &:hover {
		background-color: $tertiaryColor;
	}
    &.tertiary {
        background-color: $tertiaryColor;
    }
}

.input {
    padding: 0 1rem;
    border-radius: 0.5rem;
    border-width: 1px;
    border-color: rgba(0,0,0,.3);
    width: 100%;
    height: 3.5rem;
    font-family: $font_0, $font_1;
    overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
    outline: 1;
    &.warning {
        border-color: red;
        border-width: 2px;
    }
    &:focus {
        outline-color: $primaryColor;
    }
    &:disabled {
		background-color: #d6d6d6;
		cursor: not-allowed;
    }
}

.label {
    color: $textColor;
    margin-bottom: .5rem;
    margin-left: .5rem;
	font-size: 1.3333333333rem;
	line-height: 2rem;
	letter-spacing: 0.0333333333rem;
}

.validation-text {
    white-space: pre-wrap;
    margin-top: .5rem;
    margin-left: .5rem;
    margin-bottom: 1rem;
    font-size: 1.1rem;
    &.danger {
        color: red;
    }
    &.primary {
        color: $primaryColor;
    }
    &.secondary {
        color: $secondaryColor;
    }
    &.tertiary {
        color: $tertiaryColor;
    }
}

.icon-container {
	display: flex;
	justify-content: center;
	align-items: center;
	.icon {
		width: 1.5rem;
		height: 1.5rem;
		fill: $textColor;
		&.white {
			fill: white
		}
	}
}

.horizontal-divider {
    width: 1.5rem;
    height: auto;
    display:inline-block;
}

.vertical-divider {
    width: auto;
    height: 1.5rem;
    display:inline-block;
}

.interactive-div {
	cursor: pointer;
    color: $textColor;
	&:hover {
		color: $tertiaryColor;
        fill: $tertiaryColor;
	}
    &.secondary {
        color: $secondaryColor;
    }
	&.tertiary {
        color: $tertiaryColor;
    }
	&.white {
        color: white;
    }
	a {
		color: black;
		width: 100%;
		&:hover {
			color: rgba(0,0,0,.3);
		}
	}
}

.space-between {
	display: flex;
	justify-content: space-between;
}

.space-around {
	display: flex;
	justify-content: space-around;
}

.justify-start {
	display: flex;
	justify-content: flex-start;
}

.align-start {
	display: flex;
	align-items: flex-start;
}

.justify-end {
	display: flex;
	justify-content: flex-end;
}

.align-end {
	display: flex;
	align-items: flex-end;
}

.center {
	display: flex;
    justify-content: center;
    align-items: center;
}

.justify-center {
    display: flex;
    justify-content: center;
}

.align-center {
    display: flex;
    align-items: center;
}

.align-stretch {
    display: flex;
    align-items: stretch;
}

.align-end {
    display: flex;
    align-items: flex-end;
}

.justify-stretch {
    display: flex;
    justify-content: stretch;
}

.flex-grow {
    flex-grow: 1;
}

.separator:not(:last-child) {
	border-bottom: 1px solid rgba(128,128,128,.3);
}

.current-selection {
    color: $tertiaryColor;
    // font-weight: 600;
}

.horizontal-spacer {
    width: 5px;
}

.vertical-spacer {
    height: 5px;
}

.fixed-icon {
    height: 1rem;
    width: 1rem;
    z-index: 10;
    position: fixed;
    &.targetable {
        cursor: pointer;
    }
    &.left {
        // left: 5px;
        top: 50%;
    }
    &.right {
        right: 5px;
        top: 50%;
    }
    &.bottom {
        bottom: 5px; 
        &.left {
            left: 5px;
        }
        &.right {
            right: 5px;
        }
    }
    &.top {
        top: 5px; 
        &.left {
            left: 5px;
        }
        &.right {
            right: 5px;
        }
    }
}

.text-uppercase {
    text-transform: uppercase;
}

.text-lowercase {
    text-transform: lowercase;
}

.underline {
    text-decoration: underline;
}

.italic {
    font-style: italic;
}

.bold {
    font-weight: bold;
}

.accent-primary {
    color: $primaryColor;
    fill: $primaryColor;
}

.accent-secondary {
    color: $secondaryColor;
    fill: $secondaryColor;
}

.accent-tertiary {
    color: $tertiaryColor;
    fill: $tertiaryColor;
}

@media screen and (max-device-width: 768px) {
    html { font-size: .9vh; }
    body { font-size: 20px; }
    input, select { font-size: 100%; }
    
    .form-container {
        width: 100%;
    }
}
