@import 'core/styling/App.scss';

.button {
	padding: 0 1rem;
	width: 100%;
	height: 3.5rem;
	color: $iconButtonFill;
	background-color: $primaryColor;
	border: none;
	border-radius: 0.4rem;
	font-size: 1.111111rem;
	letter-spacing: 0.0333333333rem;
	cursor: pointer;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	outline: 0;
    &:hover {
		background-color: $tertiaryColor;
	}
	&:active {
		background-color: $secondaryColor;
	}
	&:disabled {
		color: #7a7a7a;
		background-color: #b8b8b8;
		cursor: not-allowed;
    }
    &.secondary {
        background-color: $secondaryColor;
    }
    &.tertiary {
        background-color: $tertiaryColor;
        &:hover {
            opacity: .8;
        }
    }
    &.danger {
        background-color: #e03939;
        &:hover {
            background-color: lighten(#e03939, 10);
            // background-color: rgb(180, 0, 0);
        }
        &:active {
            background-color: darken(#e03939, 10);
            // background-color: rgb(214, 0, 0);
        }
    }
    &.selected {
        background-color: $tertiaryColor;
    }
    &.small {
        height: 3rem;
    }
}

.button-label {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}