.fade-enter {
	opacity: 0.01;
	&.fade-enter-active {
		opacity: 1;
		transition: opacity 300ms ease-in;
	}
}

.fade-exit {
	opacity: 1;
	&.fade-exit-active {
		opacity: 0.01;
		transition: opacity 300ms ease-in;
	}
}

div.transition-group {
	position: relative;
}

section.route-section {
    position: absolute;
    padding-bottom: 80px;
    width: 100%;
    transition: 800ms;
}

section.route-section.slide-done {
    position: relative;
}

.left .slide-enter {
    transform: translateX(100%);
}

.left .slide-enter-active {
    transform: translateX(0%);
}

.left .slide-exit {
    transform: translateX(-100%);
}

.left .slide-exit-active {
    transform: translateX(-100%);
}

.right .slide-enter {
    transform: translateX(-100%);
}

.right .slide-enter-active {
    transform: translateX(0%);
}

.right .slide-exit {
    transform: translateX(100%);
}

.slider {
    animation: slider 500ms;
}

@keyframes slider {
	from {
		transform: translateX(100%);
	}
	to {
		transform: translateX(0%);
	}
}