// .hours {
//     height: var(--height);
    // text-transform: uppercase;
    // font-weight: 400;
//     color: var(--secondaryColor);
//     // color: var(--textColorLight);
//     font-size: 80%;
//     text-align: right;
// }
@import 'core/styling/App.scss';

.timeline-col {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
    padding-right: 8px;
    margin-top: -12.5px;
    font-size: 80%;
}

.timeline-hour {
    width: 100%;
    height: 100%;
    flex-grow: 1;
}

.timeline-header-margin {
    min-height: 45px; // height of day-header and day-spacing -> Day.scss
}