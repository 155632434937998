//colors
$white: #fff;
$color_silver_approx: #c7c7c7;
$color_mercury_approx: rgb(226, 226, 226);
$black_20: rgba(0, 0, 0, .2);
$black_25: rgba(0, 0, 0, .25);
$gallery: #efefef;

.dropdown-container {
	display: flex;
	align-items: center;
	// height: 40px;
	// width: auto;
	box-sizing: border-box;
	border-radius: 2rem;
	position: relative;
}

.dropdown-label {
	cursor: pointer;
	position: relative;
	color: $color_mercury_approx;
}

.dropdown-active {
	height: 30px;
	line-height: 30px;
	vertical-align: middle;
}

.dropdown-select {
	// width: fit-content;
	position: absolute;
	border-radius: .3rem;
	top: 32px;
	left: 0;
	right: 0;
	z-index: 5;
	background: $white;
	box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 -16px 24px 2px rgba(0, 0, 0, 0.14);
	overflow-y: scroll;
	max-height: 0;
	height: auto;
	transition: max-height 300ms ease-in-out;
	list-style: none;
	padding: 0;
	&.open {
		height: fit-content;
		max-height: 500px;
	}
}

.dropdown-option {
	width: 100%;
	text-align: center;
	padding: 12px;
	margin: 0;
	cursor: pointer;
	position: relative;
	z-index: 2;
	display: block;
	font-size: 12px;
	font-weight: normal;
	&:hover {
		background: $gallery;
	}
}

.open .dropdown-label:after {
	transform: rotate(180deg);
}