.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 9;
    animation: fade-in 500ms;
}
  
.modal-main {
    position: fixed;
    background: white;
    width: 600px;
    top: 50%;
    left: 50%;
    border-radius: .2rem;
    padding: 2rem;
	box-shadow: 0 0.2rem 0.5rem 0.2rem rgba(0, 0, 0, 0.06);
    transform: translate(-50%,-50%);
}

@media screen and (max-device-width: 768px) {
    .modal-main {
		width: 90%;
	}
}
  
.display-block {
    display: block;
}
  
.display-none {
    display: none;
}

.modal-close-button {
    position: fixed;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
}

@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}

}