@import 'core/styling/App.scss';

.calendar-container {
    display: flex;
    width: 100%;
    height: 1200px;
}

.calendar-toolbar {
    padding-bottom: 20px;
}

.date {
    text-transform: uppercase;
}

.navigation {
    display: flex;
    align-items: center;
}

.navigation-icon {
    height:1.8rem;
    width: 1.8em;
    &:hover {
        cursor: pointer;
        fill: $tertiaryColor;
        // border-radius: .2rem;
        // box-shadow: 0.3px .2rem 0.5rem .2rem rgba(0, 0, 0, .26);
    }
}

@media screen and (max-device-width: 768px){
    .calendar-container {
        height: 1400px;
    }

    .calendar-toolbar {
        font-size: 26px;
    }
}