@import 'core/styling/App.scss';

// @mixin background-opacity($color, $opacity: 0.5) {
//     background-color: $color;
//     background-color: rgba($color, $opacity);
// }

.dashboard-row {
    display: flex;
    flex-direction: row;
    margin: 0 -10px 0 0;
    padding-bottom: 10px;
}

.dashboard-item {
    width: 100%;
    margin: 0 10px 0 0;
}

.one {
    // flex-basis: 100%;
    flex-basis: calc(100% / 3);
}

.two {
    // flex: 100%; 
    flex: calc((100% / 3) * 2); 
}

.announcement {
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;

}

.dashboard-icon {
    fill: $primaryColor;
    height: 3rem; 
    width: 3rem;
    &.large {
        height: 4rem; // 2 x line-height (App.scss -> .headline)
        width: 4rem;
    }
    &.small {
        height: 2rem; // 2 x line-height (App.scss -> .headline)
        width: 2rem;
    }
    &.white {
        fill: white;
    }
}

.text-input {
    padding: 1rem 1rem;
    border-radius: 0.5rem;
    border-width: 1px;
    border-color: rgba(0,0,0,.3);
    width: 100%;
    resize: none;
    height: 7rem;
    font-family: $font_0, $font_1;
    font-size: 16px;
    overflow: hidden;
	text-overflow: ellipsis;
    outline: 1;
    &.warning {
        border-color: rgba(255, 0, 0, .5);
        border-width: 2px;
    }
    &:focus {
        outline-color: $primaryColor;
    }
}

.increase {
    color: green;
    fill: green;
}

.decrease {
    color: red;
    fill: red;
}

hr.dashboard {
    // border: 0;
    height: 1px;
    background: $primaryColor;
    opacity: 0.4;
}



.activity-icon {
    background-color: $primaryColor;
    width: 3rem;
    height: 3rem;
    border-radius: .2rem;
}

@media screen and (max-device-width: 768px){
    // .card-box {
    //     width: 100%;
    // }
    .dashboard-row {
        flex-wrap: wrap;
    }
    .one {
        flex-basis: 100%;
    }
    .two {
        flex-basis: 100%;
    }
}