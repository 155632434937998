.input {
    position: relative;
    padding: 0 1rem;
    border-radius: 0.5rem;
    border-width: 1px;
    border-color: rgba(0,0,0,.3);
    width: 100%;
    height: 3.5rem;
    overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
    outline: 1;
    &.warning {
        border-color: red;
        border-width: 2px;
    }
    &.read-only {
        cursor: pointer;
        caret-color: transparent;
    }
    &:focus {
        outline-color: $primaryColor;
    }
    &:disabled {
		background-color: #d6d6d6;
		cursor: not-allowed;
    }
}

.input-label {
    margin-bottom: .5rem;
    margin-left: .5rem;
	font-size: 1.3333333333rem;
	line-height: 2rem;
	letter-spacing: 0.0333333333rem;
}

.select-input {
	position: absolute;
	border-radius: .5rem;
	top: 30px;
	left: 0;
	right: 0;
	z-index: 5;
    padding: 30px;
	background: white;
	box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 -16px 24px 2px rgba(0, 0, 0, 0.14);
	overflow-y: scroll;
	max-height: 0;
	height: auto;
	transition: max-height 300ms ease-in-out;
	list-style: none;
	padding: 0;
	&.open {
		height: fit-content;
		max-height: 300px;
	}
}

.select-item {
    padding: 10px;
    cursor: pointer;
    &:hover {
        background-color: rgba(0, 0, 0, 0.04);
    }
}