@import 'core/styling/App.scss';

.tooltip {
    position: relative;
    display: inline-block;
    // border-bottom: 1px dotted black;
}
  
.tooltip .tooltiptext {
    visibility: hidden;
    width: fit-content;
    font-size: 14px;
    min-width: 120px;
    background-color: $secondaryColor;
    color: #fff;
    text-align: center;
    border-radius: .2rem;
    padding: 5px;
    position: absolute;
    z-index: 10;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}
  
.tooltip .tooltiptext::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: $secondaryColor transparent transparent transparent;
}
  
.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}